<template>
  <main>
    <div class="flex flex-col items-center px-3 py-3 mx-auto">
      <div class="page-title">
        <span>
          {{ $t('deposit') }}
        </span>
      </div>
      <div class="w-full tabs rounded-lg bg-gray-100 border shadow-lg mt-4">
        <div class="w-full rounded-lg mx-auto md:mt-0 sm:max-w-xl xl:p-0 bg-gray-100 border shadow-lg">
          <div
            v-if="state.configs.deposit_enable"
            class="p-4 space-y-4"
          >
            <FwbAlert
              v-if="store.userData.promotion_id"
              icon
              border
              type="success"
            >
              <div>
                {{ $t('you_have_selected_promotion') }}<br>
                <div class="font-bold text-lg">
                  {{ store.userData.promotion_name }}
                </div>
                <div>{{ $t('min_deposit') }} <span class="font-bold">{{ store.userData.promotion_deposit_min }}</span></div>
                <div class="text-red-700 font-bold">
                  <VueCountdown
                    v-slot="{ minutes, seconds }"
                    :time="timeToExpire"
                  >
                    {{ minutes }}:{{ seconds }}
                  </VueCountdown>
                </div>
                <RouterLink
                  to="/promotion"
                  class="me-2"
                >
                  <strong class="underline">{{ $t('change_promotion') }}</strong>
                </RouterLink>
                <a
                  class="me-2 cursor-pointer"
                  @click="unSelectPromotion"
                >
                  <strong class="underline text-red-700">{{ $t('dont_get_promotion') }}</strong>
                </a>
              </div>
            </FwbAlert>
            <FwbAlert
              v-else
              icon
              border
              type="danger"
            >
              {{ $t('you_have_not_selected_promotion') }}
              <RouterLink to="/promotion">
                <strong class="underline">{{ $t('select_promotion') }}</strong>
              </RouterLink>
            </FwbAlert>
            <div class="grid gap-4 grid-cols-2">
              <div>
                <div class="text-body-2">
                  {{ $t('your_bank') }}
                </div>
                <FwbAvatar :img="store.userData.bank_logo" />
                <div class="flex gap-2">
                  <div class="flex-1">
                    {{ store.userData.bank_name }}<br>
                    {{ store.userData.bank_account_number }}<br>
                    {{ store.userData.bank_account_name }}
                  </div>
                </div>
              </div>
              <div v-if="state.configs.show_deposit_bank">
                <div class="text-body-2">
                  {{ $t('transfer_to_bank') }}
                </div>
                <div v-if="adminBank">
                  <FwbAvatar :img="adminBank.bank_logo" />
                  <div class="flex-1">
                    {{ adminBank.bank_name }}<br>
                    <span class="text-xl font-bold text-red-800">
                      {{ adminBank.bank_account_number }}
                      <FwbButton
                        type="button"
                        size="xs"
                        outline
                        class="ms-1 inline"
                        @click="copyToClipboard(adminBank.bank_account_number)"
                      >
                        Copy
                      </FwbButton>
                    </span>
                    <br>
                    {{ adminBank.bank_account_name }}
                  </div>
                </div>
              </div>
            </div>
            <form
              class="space-y-4 md:space-y-6"
              @submit.prevent="generateQrCode"
            >
              <div class="my-5">
                <FwbInput
                  v-model="state.deposit.amount"
                  :placeholder="$t('deposit_amount')"
                  :label="$t('deposit_amount')"
                  class="text-center"
                  :class="{'is-invalid': v$.amount.$error, 'is-valid': v$.amount.$dirty && !v$.amount.$error}"
                  @keydown="checkDigit"
                >
                  <template #helper>
                    ({{ $t('deposit_min') }} {{ num(state.configs.deposit_min) }})
                  </template>
                  <template #validationMessage>
                    <div
                      v-for="(error, i) in v$.amount.$errors"
                      :key="i"
                      class="text-red-500 text-xs ps-1"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
                </FwbInput>
              </div>
              <div class="mb-5">
                <div class="grid gap-1 gap-y-2 grid-cols-4">
                  <div
                    v-for="amount in state.amounts"
                    :key="amount"
                    class="text-center"
                  >
                    <FwbButton
                      type="button"
                      gradient="green-blue"
                      @click="setAmount(amount)"
                    >
                      {{ num(amount) }}
                    </FwbButton>
                  </div>
                </div>
              </div>
              <div class="my-5 text-center">
                <FwbButton>
                  <div class="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-qrcode"
                    ><path
                      stroke="none"
                      d="M0 0h24v24H0z"
                      fill="none"
                    /><path d="M4 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" /><path d="M7 17l0 .01" /><path d="M14 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" /><path d="M7 7l0 .01" /><path d="M4 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" /><path d="M17 7l0 .01" /><path d="M14 14l3 0" /><path d="M20 14l0 .01" /><path d="M14 14l0 3" /><path d="M14 20l3 0" /><path d="M17 17l3 0" /><path d="M20 17l0 3" /></svg>
                    <div class="pt-1">
                      {{ $t('generate_qrcode') }}
                    </div>
                  </div>
                </FwbButton>
              </div>
              <div class="mb-5 svg-img space-y-2">
                <!-- eslint-disable -->
                <div v-if="state.qrcode" v-html="state.qrcode" />
                <!-- eslint-enable -->
                <div class="text-xl text-primary font-bold text-center">
                  {{ $t('amount') }} {{ num(state.deposit.amount) }} {{ $t('baht') }}
                </div>
                <div class="text-lg text-primary text-center">
                  {{ $t('promptpay_mobile') }} {{ state.mobile }}
                </div>
              </div>
            </form>
            <div
              v-if="state.configs.deposit_system === 'slip'"
              class="my-5"
            >
              <FwbFileInput
                v-model="state.file"
                dropzone
                :label="$t('select_file')"
                class="py-3 dropzone"
                @change="onFileChange"
              />
              <div class="my-5">
                <FwbImg
                  v-if="state.deposit.image"
                  alt="Slip"
                  :src="state.deposit.image"
                />
                <div class="my-5 text-center">
                  <FwbButton @click="depositRequest">
                    {{ $t('confirm_deposit') }}
                  </FwbButton>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="mx-auto sm:max-w-xl text-center"
          >
            <FwbAlert
              icon
              border
              type="danger"
            >
              {{ $t('deposit_not_enable') }}
            </FwbAlert>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import {
  FwbAlert,
  FwbButton,
  FwbCard,
  FwbImg,
  FwbInput,
  FwbAvatar,
  FwbFileInput,
} from 'flowbite-vue'
import { useStore } from '@/stores'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@/utils/i18n-validators'
import VueCountdown from '@chenfengyuan/vue-countdown'

const { t } = useI18n()
const title = useTitle()
const route = useRoute()
const router = useRouter()
const store = useStore()
const Swal = inject('$swal')

definePage({
  meta: {
    action: 'account',
    subject: 'all',
  },
})

title.value = t('deposit')

const appName = import.meta.env.VITE_APP_NAME

const state = reactive({
  activeTab: t('deposit-by-bank'),
  showDialog: false,
  isLoading: false,
  current: null,
  ajaxLoaded: false,
  user: store.userData,
  to: {
    id: null,
  },
  deposit: {
    amount: 100,
    image: null,
    admin_bank_id: null,
    to_bank_id: null,
    to_bank_name: null,
    to_bank_account_number: null,
    to_bank_account_name: null,
  },
  amounts: [100, 200, 300, 400, 500, 1000, 5000, 10000],
  editable: false,
  mobile: null,
  qrcode: null,
  configs: {},
  promotion: null,
  promotions: [],
  banks: [],
  adminBanks: [],
  file: null,
})

const rules = {
  amount: { required },
}

const v$ = useVuelidate(rules, state.deposit, { $lazy: true, $autoDirty: true })

onMounted(() => {
  getData()
})

const timeToExpire = computed(() => {
  return store.userData.promotion_expire - (new Date()).getTime()
})

const onFileChange = async () => {
  if (!state.file)
    return

  const formData = new FormData()

  formData.append('file', state.file)

  const { data, error, response } = await useApi('api/0/upload').post(formData, null)
  if (error.value) {
    const resp = await response.value.json()

    swal('Error?', resp.message, 'error')
  } else if (data.value.success) {
    state.deposit.image = data.value.url
  } else if(data.value.message) {
    swal('Error?', data.value.message, 'error')
  }
}

async function depositRequest() {
  if (! state.deposit.image) {
    swal('Error?', t('please_attach_payslip'), 'error')

    return
  }

  state.deposit.admin_bank_id = adminBank.value.id
  state.deposit.to_bank_id = adminBank.value.bank_id
  state.deposit.to_bank_name = adminBank.value.bank_name
  state.deposit.to_bank_account_number = adminBank.value.bank_account_number
  state.deposit.to_bank_account_name = adminBank.value.bank_account_name

  if (state.isLoading) {
    return
  }
  state.isLoading = true

  const { data, error, response } = await useApi('api/0/deposit').post(state.deposit)

  state.isLoading = false
  if (error.value) {
    const json = await response.value.json()

    swal('Error?', json.message, 'error')
  } else if (data.value?.success) {
    swal('Success?', data.value.message, 'success')
    state.deposit.image = null
    state.qrcode = null
  } else if (data.value?.message) {
    await swal('Error?', data.value.message, 'error')
  }
}

function setAmount(amount) {
  if (amount < state.configs.deposit_min) {
    state.deposit.amount = state.configs.deposit_min
  } else {
    state.deposit.amount = amount
  }
}

async function getData() {
  state.isLoading = true

  const { data, error, response } = await useApi('api/0/deposit').get()

  state.isLoading = false
  if (error.value) {
    const json = await response.value.json()

    swal('Error?', json.message, 'error')
  } else if (data.value.success) {
    state.ajaxLoaded = true
    state.configs = data.value.configs
    state.promotions = data.value.promotions
    state.banks = data.value.banks
    state.adminBanks = data.value.adminBanks
  } else if(data.value.message) {
    swal('Error?', data.value.message, 'error')
  }
}

const adminBank = computed(() => {
  return state.adminBanks.length ? state.adminBanks[0] : null
})

async function generateQrCode() {
  const result = await v$.value.$validate()
  if(result) {
    const { data, error, response } = await useApi('api/0/deposit-qrcode').post({
      amount: state.deposit.amount,
      admin_bank_id: adminBank.value?.id,
      promotion_id: state.promotion?.id,
    })

    if (error.value) {
      const json = await response.value.json()

      swal('Error?', json.message, 'error')
    } else if (data.value?.success) {
      state.qrcode = data.value.qrcode
      state.mobile = data.value.mobile
    } else if (data.value?.message) {
      await swal('Error?', data.value.message, 'error')
    }
  }
}

async function unSelectPromotion() {
  state.isLoading = true

  const { data, error, response } = await useApi('api/0/unselect-promotion').post()

  state.isLoading = false
  if (error.value) {
    const json = await response.value.json()

    swal('Error?', json.message, 'error')
  } else if (data.value?.success) {
    store.updateUserData(data.value)
    await swal('Success?', data.value.message, 'success')
  } else if (data.value?.message) {
    await swal('Error?', data.value.message, 'error')
  }
}
</script>

<style>
.svg-img svg {
  max-width: 250px;
  width: 100%;
  height: auto;
  margin: 0 auto;
}
</style>
