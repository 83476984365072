<template>
  <main v-if="!liffLoading">
    <LoginForm />
    <div>
      <div>
        <hr class="h-px w-[92px] mx-auto my-4 bg-gray-200 border-1 border-[#000000]">
        <div class="h-[66px] w-full flex bg-gradient-to-r from-[#FE59AF00] via-[#F6299338] to-[#FE59AF00] p-1">
          <img
            src="/img/logo2.png"
            class="mx-auto"
          >
        </div>
        <img
          src="/img/text-animation.gif"
          class="mx-auto mt-[2px]"
        >
      </div>
      <div class="swiper my-4 swiper1">
        <div class="swiper-wrapper">
          <div
            v-for="item in state.topBanners"
            :key="item.id"
            class="swiper-slide"
          >
            <a
              :href="item.link"
              :title="item.name"
            >
              <FwbImg
                :alt="item.name"
                :src="item.image"
                @error="imageError"
              />
            </a>
          </div>
        </div>
        <div class="swiper-pagination"></div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>

      <div class="grid grid-cols-2 gap-3 py-3 mb-3">
        <div
          v-for="item in state.gameBanners"
          :key="item.id"
          class="cursor-pointer"
          @click="gameAction(item)"
        >
          <FwbImg
            :alt="item.name"
            :src="item.image"
            @error="imageError"
          />
        </div>
      </div>

      <div
        class="grid px-[12px] gap-x-[5px] tab-nav"
        :class="`${state.sticky} grid-cols-${gameTabs.length}`"
      >
        <button
          v-for="tab in gameTabs"
          :key="tab.title"
          class="mx-auto game-tab"
          :class="{active: tab.tab === state.activeTab}"
          @click="switchTab(tab.tab)"
        >
          <img
            :src="tab.image"
            class="mx-auto"
            :alt="tab.title"
          >
          <p class="p-[5px] text-[#7C8395] text-[13px] font-[500] leading-[23.1px] mt-[3px] rounded-t-lg">
            {{ $t(tab.title) }}
          </p>
        </button>
      </div>
      <div class="bg-[#070707] mt-[-10px]">
        <template v-if="state.isLoading">
          <div class="grid grid-cols-3 gap-3 p-3">
            <div
              v-for="i in [1, 2, 3, 4, 5, 6, 7, 8, 9]"
              :key="i"
              class="relative flex items-center justify-center border rounded-xl aspect-[30/36] w-full h-full"
            >
              <svg
                class="w-10 h-10 text-gray-200 dark:text-gray-600"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 18"
              >
                <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
              </svg>
            </div>
          </div>
        </template>
        <template v-else>
          <template v-if="!state.provider && state.providers[state.activeTab] !== undefined">
            <div class="grid grid-cols-3 gap-3 p-3 min-h-[250px]">
              <div
                v-for="(item, i) in state.providers[state.activeTab]"
                :key="i"
                class="relative game-wrap hover:scale-105 transition-transform duration-300 ease-in-out"
              >
                <FwbImg
                  :alt="item.providerName"
                  :src="item.image_vertical"
                  class="game-image"
                  @error="imageError"
                  @click="selectProvider(item)"
                />
              </div>
            </div>
          </template>
          <template v-if="state.provider && state.provider.games?.length">
            <div class="pt-3 ps-3">
              <FwbButton
                shadow
                outlined
                @click="backToProviderList"
              >
                <i class="tabler-arrow-back-up text-2xl v-bottom" />
                {{ $t('back') }} {{ state.provider.provider }}
              </FwbButton>
            </div>
            <div class="grid grid-cols-3 gap-3 p-3">
              <div
                v-for="(item, i) in state.provider.games"
                :key="i"
                class="relative game-wrap hover:scale-105 transition-transform duration-300 ease-in-out"
              >
                <FwbImg
                  :alt="item.gameName"
                  :src="item.image_vertical ?? '/img/no-image.png'"
                  class="game-image"
                  @error="imageError"
                  @click="startGame(item)"
                />
              </div>
            </div>
          </template>
        </template>
      </div>

      <div class="swiper my-4 swiper2">
        <div class="swiper-wrapper">
          <div
            v-for="item in state.bottomBanners"
            :key="item.id"
            class="swiper-slide"
          >
            <a
              :href="item.link"
              :title="item.name"
            >
              <FwbImg
                :alt="item.name"
                :src="item.image"
                @error="imageError"
              />
            </a>
          </div>
        </div>
        <div class="swiper-pagination"></div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </div>
  </main>
  <template v-else>
    <Loading />
  </template>
</template>

<script setup>
import { FwbButton, FwbImg } from 'flowbite-vue'
import { useStore } from '@/stores'
import { useScreen } from 'vue-screen'
import Loading from '@/components/Loading.vue'
import LoginForm from '@/components/LoginForm.vue'
import Swiper from 'swiper'
import 'swiper/css'
import { Navigation, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'


const { t } = useI18n()
const title = useTitle()
const store = useStore()
const route = useRoute()
const router = useRouter()
const Swal = inject('$swal')
const screen = useScreen()
const { locale } = useI18n({ useScope: 'global' })

const liffLoading = computed(() => route.query['liff.state'])

definePage({
  meta: {
    public: true,
  },
})

title.value = 'Home'

let swiper1
let swiper2

const gameTabs = [
  {
    title: 'casino',
    tab: 'Casino',
    image: '/img/index/icon_gamepad_active.svg',
  },

  // {
  //   title: 'lottery',
  //   tab: 'Lottery',
  //   image: '/img/index/icon_numbered.svg',
  // },
  {
    title: 'slots',
    tab: 'Slot',
    image: '/img/index/icon_dice.svg',
  },
  {
    title: 'games',
    tab: 'Fbs',
    image: '/img/index/icon_coin.svg',
  },
  {
    title: 'fishing',
    tab: 'Fish',
    image: '/img/index/icon_fishing.svg',
  },
  {
    title: 'sports',
    tab: 'Keno',
    image: '/img/index/icon_basketball.svg',
  },
  {
    title: 'pokers',
    tab: 'Poker',
    image: '/img/index/icon_card.svg',
  },
]

const state = reactive({
  activeTab: 'Casino',
  showDialog: false,
  showMenu: false,
  isLoading: false,
  starting: false,
  provider: null,
  providers: [],
  loadedTab: {},
  sticky: '',
  gameBanners: [],
  topBanners: [],
  bottomBanners: [],
})

let navBar
let offsetTop

onMounted(() => {
  navBar = document.querySelector('.tab-nav')
  offsetTop = navBar.offsetTop
  getData(state.activeTab)
  getHomeData()
  window.onscroll = scrollFunction
  swiper1 = new Swiper('.swiper1', {
    modules: [Navigation, Pagination],
  })
  swiper2 = new Swiper('.swiper2', {
    modules: [Navigation, Pagination],
  })
})

function selectProvider(item) {
  state.provider = item
}

function backToProviderList() {
  state.provider = null
}

function switchTab(tab) {
  state.activeTab = tab
  if (! state.loadedTab[tab]) {
    getData(tab)
  }
}

async function getHomeData() {
  state.isLoading = true

  const { data, error, response } = await useApi('api/0/home-data').get()

  if (error.value) {
    const json = await response.value.json()

    swal('Error?', json.message, 'error')
  } else if (data.value.success) {
    state.gameBanners = data.value.gameBanners
    state.topBanners = data.value.topBanners
    state.bottomBanners = data.value.bottomBanners
  } else if(data.value.message) {
    swal('Error?', data.value.message, 'error')
  }
  state.isLoading = false
}

function gameAction(item) {
  if (item.link_type === 'provider') {
    selectProvider(item.link_provider)
  } else if (item.link_type === 'game') {
    startGame(item)
  } else if (item.link_type === 'url') {
    window.open(item.link)
  }
}

async function getData(tab) {
  state.isLoading = true

  const { data, error, response } = await useApi('api/0/game-list').get()

  if (error.value) {
    const json = await response.value.json()

    swal('Error?', json.message, 'error')
  } else if (data.value.success) {
    state.providers[tab] = data.value.providers
    state.loadedTab[tab] = true
  } else if(data.value.message) {
    swal('Error?', data.value.message, 'error')
  }
  state.isLoading = false
}

async function startGame(item) {
  if (! store.userData) {
    router.push('/login')

    return
  }
  if (store.userData.password_reset) {
    router.push('/user/profile#change-password')

    return
  }
  if (state.starting) {
    return
  }
  state.starting = true

  const languages = {
    'vi': 'vn',
    'lo': 'la',
    'my': 'mm',
  }

  const { data, error, response } = await useApi(createUrl('api/0/start-game')).post({
    gameID: item.game_id,
    provider: state.provider.provider,
    redirectUrl: window.location.href,
    language: languages[locale.value] ?? locale.value,
    tab: state.activeTab,
    device: screen.width >= 640 ? 'desktop' : 'mobile',
  })

  state.starting = false
  if (error.value) {
    const json = await response.value.json()

    swal('Error?', json.message, 'error')
  } else if (data.value.success) {
    window.location.href = data.value.url
  } else if(data.value.message) {
    swal('Error?', data.value.message, 'error')
  }
}


function scrollFunction() {
  if (document.body.scrollTop > offsetTop || document.documentElement.scrollTop > offsetTop) {
    console.log('scroll', document.body.scrollTop, offsetTop)
    state.sticky = 'sticky'
  } else {
    state.sticky = ''
  }
}
</script>

<style lang="scss" scoped>
.game-tab:hover p,
.game-tab.active p {
  @apply bg-[#070707] text-[#FE59AF];
}
.tab-nav.sticky {
  top: 89px;
  background-color: #fff;
  z-index: 1;
}
.game-image {
  @apply border rounded-xl cursor-pointer w-full aspect-[30/36];
}
.game-wrap:hover .game-image {
  background-color: #ffffff;
}
</style>
