<script setup>
import { FwbInput, FwbButton, FwbSelect } from 'flowbite-vue'
import { useStore } from '@/stores'
import { useVuelidate } from '@vuelidate/core'
import { required, mobile, sameAs, strongPassword } from '@/utils/i18n-validators'

const { t } = useI18n()
const emitter = inject('emitter')
const title = useTitle()
const router = useRouter()
const store = useStore()
const Swal = inject('$swal')

definePage({
  meta: {
    public: true,
  },
})

title.value = t('register')

const appName = import.meta.env.VITE_APP_NAME

const pass = ref(null)

const state = reactive({
  isLoading: false,
  prefix: '',
  first_name: '',
  last_name: '',
  pin: '',
  mobile: '',
  line_id: '',
  line_name: '',
  password: '',
  password_confirmation: '',
  bank_id: '',
  bank_account_number: '',
  referer: '',
  showPassword: false,
  showPasswordConfirm: false,
  banks: [],
  prefixes: [
    { name: t('Mr'), value: 'Mr' },
    { name: t('Ms'), value: 'Ms' },
    { name: t('Mrs'), value: 'Mrs' },
  ],
  sources: [],
})

const newPassword = computed(() => state.password)

const rules = {
  prefix: { required },
  first_name: { required },
  last_name: { required },
  mobile: { required, mobile },
  password: { required, strongPassword },
  password_confirmation: { required, sameAs: sameAs(newPassword, t('password')) },
  bank_id: { required },
  bank_account_number: { required },
}

const v$ = useVuelidate(rules, state, { $lazy: true, $autoDirty: true })

onMounted(() => {
  getBanks()
  getSources()
})

function togglePassword() {
  state.showPassword = !state.showPassword
}

function togglePasswordConfirm() {
  state.showPasswordConfirm = !state.showPasswordConfirm
}

const onSubmit = async () => {
  const result = await v$.value.$validate()
  if(result) {
    if (state.isLoading) {
      return
    }
    state.isLoading = true

    const { data, error, response } = await useApi('/api/0/register').post({
      source: state.source,
      prefix: state.prefix,
      first_name: state.first_name,
      last_name: state.last_name,
      mobile: state.mobile,
      password: state.password,
      password_confirmation: state.password,
      bank_id: state.bank_id,
      bank_account_number: state.bank_account_number,
      bank_account_name: `${state.first_name} ${state.last_name}`,
      ref: useCookie('ref').value,
      marketing_username: useCookie('marketing_username').value,
    })

    state.isLoading = false
    if (error.value) {
      const json = await response.value.json()

      swal('Error?', json.message, 'error')
    } else if (data.value.twofa) {
      swal('Success?', data.value.message, 'success')
      state.twofa = true
      state.id = data.value.id
    } else if (data.value.success) {
      store.updateUserData(data.value)
      emitter.emit('logged-in')
      await swal('Success', data.value.message, 'success')
      await router.replace('/')
    } else if(data.value.message) {
      swal('Error?', data.value.message, 'error')
    }
  }
}

async function getBanks() {
  const { data, error, response } = await useApi('api/0/banks').get()

  if (data.value?.success) {
    state.banks = data.value?.banks
  }
}

async function getSources() {
  const { data, error, response } = await useApi('api/0/sources').get()

  if (data.value?.success) {
    state.sources = data.value?.sources
  }
}
</script>

<template>
  <main>
    <div class="flex flex-col items-center justify-center px-3 py-3 mx-auto">
      <div class="page-title">
        <span>
          {{ $t('register') }}
        </span>
      </div>
      <div class="w-full rounded-lg md:mt-0 sm:max-w-xl xl:p-0 bg-gray-100 border shadow-lg">
        <div
          v-if="store.settings?.allow_register"
          class="p-6 space-y-4 md:space-y-6"
        >
          <form
            novalidate
            @submit.prevent="onSubmit"
          >
            <div class="grid gap-3 grid-cols-2">
              <!-- source -->
              <div class="mb-1">
                <FwbSelect
                  v-model="state.source"
                  :placeholder="$t('how_did_you_know_us')"
                  :label="$t('how_did_you_know_us')"
                  :options="state.sources.map(o => {o.name = o.title; return o})"
                />
              </div>
              <!-- prefix -->
              <div class="mb-1">
                <FwbSelect
                  v-model="state.prefix"
                  :placeholder="$t('prefix')"
                  :label="$t('prefix')"
                  :options="state.prefixes"
                  :class="{'is-invalid': v$.prefix.$error, 'is-valid': v$.prefix.$dirty && !v$.prefix.$error}"
                >
                  <template #validationMessage>
                    <div
                      v-for="(error, i) in v$.prefix.$errors"
                      :key="i"
                      class="text-red-500 text-xs ps-1"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
                </FwbSelect>
              </div>
              <!-- first_name -->
              <div class="mb-3">
                <FwbInput
                  v-model="state.first_name"
                  :placeholder="$t('first_name')"
                  :label="$t('first_name')"
                  maxlength="150"
                  :class="{'is-invalid': v$.first_name.$error, 'is-valid': v$.first_name.$dirty && !v$.first_name.$error}"
                  @keydown="checkNonDigitAndSpecial"
                >
                  <template #validationMessage>
                    <div
                      v-for="(error, i) in v$.first_name.$errors"
                      :key="i"
                      class="text-red-500 text-xs ps-1"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
                </FwbInput>
              </div>
              <!-- last_name -->
              <div class="mb-3">
                <FwbInput
                  v-model="state.last_name"
                  :placeholder="$t('last_name')"
                  :label="$t('last_name')"
                  maxlength="150"
                  :class="{'is-invalid': v$.last_name.$error, 'is-valid': v$.last_name.$dirty && !v$.last_name.$error}"
                  @keydown="checkNonDigitAndSpecial"
                >
                  <template #validationMessage>
                    <div
                      v-for="(error, i) in v$.last_name.$errors"
                      :key="i"
                      class="text-red-500 text-xs ps-1"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
                </FwbInput>
              </div>
            </div>
            <div class="grid gap-3 grid-cols-2">
              <!-- mobile -->
              <div class="mb-3">
                <FwbInput
                  v-model="state.mobile"
                  :placeholder="$t('mobile')"
                  :label="$t('mobile')"
                  maxlength="10"
                  :class="{'is-invalid': v$.mobile.$error, 'is-valid': v$.mobile.$dirty && !v$.mobile.$error}"
                  @keydown="checkDigit"
                >
                  <template #validationMessage>
                    <div
                      v-for="(error, i) in v$.mobile.$errors"
                      :key="i"
                      class="text-red-500 text-xs ps-1"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
                </FwbInput>
              </div>
              <!-- password -->
              <div class="mb-3">
                <FwbInput
                  ref="pass"
                  v-model="state.password"
                  :placeholder="$t('set_password')"
                  :label="$t('set_password')"
                  :type="state.showPassword ? 'text' : 'password'"
                  autocomplete="off"
                  maxlength="50"
                  :class="{'is-invalid': v$.password.$error, 'is-valid': v$.password.$dirty && !v$.password.$error}"
                >
                  <template #validationMessage>
                    <div
                      v-for="(error, i) in v$.password.$errors"
                      :key="i"
                      class="text-red-500 text-xs ps-1"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
                  <template #suffix>
                    <div
                      v-if="state.password"
                      class="mt-2 cursor-pointer text-gray-500"
                      @click="togglePassword"
                    >
                      <svg
                        v-if="state.showPassword"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="icon icon-tabler icons-tabler-outline icon-tabler-eye"
                      ><path
                        stroke="none"
                        d="M0 0h24v24H0z"
                        fill="none"
                      /><path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" /></svg>
                      <svg
                        v-else
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="icon icon-tabler icons-tabler-outline icon-tabler-eye-off"
                      ><path
                        stroke="none"
                        d="M0 0h24v24H0z"
                        fill="none"
                      /><path d="M10.585 10.587a2 2 0 0 0 2.829 2.828" /><path d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" /><path d="M3 3l18 18" /></svg>
                    </div>
                  </template>
                </FwbInput>
              </div>
              <!-- password_confirmation -->
              <div class="mb-3">
                <FwbInput
                  v-model="state.password_confirmation"
                  :placeholder="$t('confirm_password')"
                  :label="$t('confirm_password')"
                  :type="state.showPasswordConfirm ? 'text' : 'password'"
                  autocomplete="off"
                  maxlength="50"
                  :class="{'is-invalid': v$.password_confirmation.$error, 'is-valid': v$.password_confirmation.$dirty && !v$.password_confirmation.$error}"
                >
                  <template #validationMessage>
                    <div
                      v-for="(error, i) in v$.password_confirmation.$errors"
                      :key="i"
                      class="text-red-500 text-xs ps-1"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
                  <template #suffix>
                    <div
                      v-if="state.password_confirmation"
                      class="mt-2 cursor-pointer text-gray-500"
                      @click="togglePasswordConfirm"
                    >
                      <svg
                        v-if="state.showPasswordConfirm"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="icon icon-tabler icons-tabler-outline icon-tabler-eye"
                      ><path
                        stroke="none"
                        d="M0 0h24v24H0z"
                        fill="none"
                      /><path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" /></svg>
                      <svg
                        v-else
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="icon icon-tabler icons-tabler-outline icon-tabler-eye-off"
                      ><path
                        stroke="none"
                        d="M0 0h24v24H0z"
                        fill="none"
                      /><path d="M10.585 10.587a2 2 0 0 0 2.829 2.828" /><path d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" /><path d="M3 3l18 18" /></svg>
                    </div>
                  </template>
                </FwbInput>
              </div>
            </div>
            <div class="grid gap-3 grid-cols-2">
              <!-- bank_id -->
              <div class="mb-3">
                <FwbSelect
                  v-model="state.bank_id"
                  :placeholder="$t('bank')"
                  :label="$t('bank')"
                  :options="state.banks"
                  :class="{'is-invalid': v$.bank_id.$error, 'is-valid': v$.bank_id.$dirty && !v$.bank_id.$error}"
                >
                  <template #validationMessage>
                    <div
                      v-for="(error, i) in v$.bank_id.$errors"
                      :key="i"
                      class="text-red-500 text-xs ps-1"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
                </FwbSelect>
              </div>
              <!-- bank_account_number -->
              <div class="mb-3">
                <FwbInput
                  v-model="state.bank_account_number"
                  :placeholder="$t('bank_account_number')"
                  :label="$t('bank_account_number')"
                  :class="{'is-invalid': v$.bank_account_number.$error, 'is-valid': v$.bank_account_number.$dirty && !v$.bank_account_number.$error}"
                  @keydown="checkDigit"
                >
                  <template #validationMessage>
                    <div
                      v-for="(error, i) in v$.bank_account_number.$errors"
                      :key="i"
                      class="text-red-500 text-xs ps-1"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
                </FwbInput>
              </div>
            </div>

            <div class="my-5">
              <FwbButton
                class="btn"
                type="submit"
              >
                {{ $t('register') }}
              </FwbButton>
            </div>
          </form>
          <hr class="mt-1">
          <div class="text-center">
            <p class="text-sm">
              {{ $t('already_a_member') }}
            </p>
            <RouterLink
              to="/login"
              class="underline underline-offset-4"
            >
              {{ $t('login') }}
            </RouterLink>
          </div>
        </div>
        <div
          v-else
          class="p-6 sm:p-8"
        >
          <div class="text-xl font-bold text-center text-red-700">
            {{ $t('registration_temporary_disabled') }}
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
