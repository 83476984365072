import { createFetch } from '@vueuse/core'
import { destr } from 'destr'
import { getAccessToken, clearAllToken } from '@/utils'
import { getI18n } from '@/plugins/i18n'
import { emitter } from '@/plugins/emitter'

const headers = {
  Accept: 'application/json',
}

if (import.meta.env.VITE_ENV === 'testing') {
  headers['X-Testing'] = 1
}

export const useApi = createFetch({
  baseUrl: import.meta.env.VITE_API_BASE_URL || '/',
  fetchOptions: {
    headers,
  },
  options: {
    refetch: false,
    async beforeFetch({ options }) {
      const accessToken = getAccessToken()
      if (accessToken) {
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${accessToken}`,
        }
      }
      options.headers = {
        ...options.headers,
        'Accept-Language': getI18n().global.locale.value,
      }

      return { options }
    },
    afterFetch(ctx) {
      const { data, response } = ctx
      emitter.emit('loaded')

      // Parse data if it's JSON
      let parsedData = null
      try {
        parsedData = destr(data)
      } catch (error) {
        if (error.code === 401) {
          clearAllToken()
          window.location.href = '/login'
        }
      }

      return { data: parsedData, response }
    },
    onFetchError(ctx) {
      if (ctx.response.status === 401) {
        clearAllToken()
        window.location.href = '/login'
      }

      return ctx
    },
  },
})

export const buildQuery = (url, query) => {
  const queryStr = new URLSearchParams(query).toString()

  return `${url}?${queryStr}`
}

export const useApiGet = (url, query) => {
  return useApi(buildQuery(url, query))
}
