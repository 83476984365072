<template>
  <main>
    <div class="flex flex-col items-center px-3 py-3 mx-auto">
      <div class="page-title">
        <span>
          {{ $t('transaction-history') }}
        </span>
      </div>
      <div class="w-full items-center px-0 pb-8 mx-auto lg:py-4 sm:max-w-[50rem]">
        <FwbTable class="table-responsive">
          <FwbTableHead>
            <FwbTableHeadCell>{{ $t('type') }}</FwbTableHeadCell>
            <FwbTableHeadCell>{{ $t('total') }}</FwbTableHeadCell>
            <FwbTableHeadCell>{{ $t('status') }}</FwbTableHeadCell>
            <FwbTableHeadCell>{{ $t('created_at') }}</FwbTableHeadCell>
          </FwbTableHead>
          <FwbTableBody>
            <template v-if="state.items.length">
              <FwbTableRow
                v-for="item in state.items"
                :key="item.id"
              >
                <FwbTableCell class="text-center">
                  <FwbBadge
                    :type="item.type === 0 ? 'green' : 'red'"
                    class="inline-block whitespace-nowrap"
                  >
                    {{ item.type === 0 ? $t('deposit') : $t('withdraw') }}
                  </FwbBadge>
                </FwbTableCell>
                <FwbTableCell
                  class="text-center text-md"
                  :class="item.type === 0 ? 'text-green-500' : 'text-red-500'"
                >
                  {{ item.type === 0 ? '+' : '-' }}{{ num(item.total) }}
                </FwbTableCell>
                <FwbTableCell class="text-center">
                  <FwbBadge
                    v-if="item.status === 1"
                    type="green"
                    class="inline-block whitespace-nowrap"
                  >
                    {{ $t('success') }}
                  </FwbBadge>
                  <div v-else>
                    <FwbBadge
                      type="red"
                      class="inline-block whitespace-nowrap"
                    >
                      {{ $t('failed') }}
                    </FwbBadge>
                    <div>
                      {{ $t('remark') }}: {{ item.remark }}
                    </div>
                  </div>
                </FwbTableCell>
                <FwbTableCell>
                  <div class="w-full text-center">
                    {{ $d(item.created_at, 'long') }}
                  </div>
                </FwbTableCell>
              </FwbTableRow>
            </template>
            <template v-else-if="state.ajaxLoaded">
              <FwbTableRow>
                <td
                  colspan="5"
                  class="px-6 py-4 text-center"
                >
                  {{ $t('no_data_text') }}
                </td>
              </FwbTableRow>
            </template>
            <template v-else>
              <FwbTableRow>
                <td
                  colspan="5"
                  class="px-6 py-4 text-center"
                >
                  {{ $t('data_is_loading') }}
                </td>
              </FwbTableRow>
            </template>
          </FwbTableBody>
        </FwbTable>
      </div>
    </div>
  </main>
</template>

<script setup>
import {
  FwbButton,
  FwbPagination,
  FwbTab,
  FwbTable,
  FwbTableBody,
  FwbTableRow,
  FwbTableCell,
  FwbTableHead,
  FwbTableHeadCell,
  FwbSelect,
  FwbTabs,
  FwbBadge,
  FwbTooltip,
  FwbModal,
} from 'flowbite-vue'
import { useStore } from '@/stores'

const { t } = useI18n()
const title = useTitle()
const route = useRoute()
const router = useRouter()
const store = useStore()
const Swal = inject('$swal')

definePage({
  name: 'transaction',
  meta: {
    action: 'account',
    subject: 'all',
  },
})

title.value = t('transaction-history')

const appName = import.meta.env.VITE_APP_NAME

const state = reactive({
  showDialog: false,
  isLoading: false,
  ajaxLoaded: false,
  user: store.userData,
  editable: false,
  page: 1,
  items: [],
  columns: [
    { title: t('type'), key: 'type', sortable: false },
    { title: t('amount'), key: 'amount', sortable: false },
    { title: t('bonus'), key: 'bonus', sortable: false },
    { title: t('total'), key: 'total', sortable: false },
    { title: t('status'), key: 'status', sortable: false },
    { title: t('created_at'), key: 'created_at', sortable: false },
  ],
})

onMounted(() => {
  getData()
})

async function getData() {
  const { data, error, response } = await useApi(createUrl('api/0/transactions', {
    query: {
      page: state.page,
    },
  })).get()

  if (error.value) {
    const json = await response.value.json()

    swal('Error?', json.message, 'error')
  } else if (data.value.success) {
    state.items = data.value.data
  } else if(data.value.message) {
    swal('Error?', data.value.message, 'error')
  }
  state.ajaxLoaded = true
}
</script>

<route lang="yaml">
meta:
  name: transaction
</route>
