import Swal from 'sweetalert2'
import { getI18n } from '@/plugins/i18n'

const t = getI18n().global.t

export const checkDigit = event => {
  if (event.key.length === 1 && isNaN(Number(event.key))) {
    if (event.metaKey && ['c', 'v', 'a', 'x'].includes(event.key)) return
    event.preventDefault()
  }
}

export const checkAlpha = event => {
  if (!/[a-zA-Z]/.test(event.key)) {
    event.preventDefault()
  }
}

export const checkNonDigitAndSpecial = event => {
  // ((event.key >= 65 && event.key <= 92) || (event.key >= 97 && event.key <= 124) || event.key === 32)
  if (/[0-9.\-+~#$%^&*()_/@!=`,\\{}\[]/.test(event.key)) {
    event.preventDefault()

  }
}

export const checkAlphaDash = event => {
  if (!/[a-zA-Z0-9_-]/.test(event.key)) {
    event.preventDefault()
  }
}

export const checkInt = event => {
  if (!/[0-9]/.test(event.key)) {
    if (event.metaKey && ['c', 'v', 'a', 'x'].includes(event.key)) return
    event.preventDefault()
  }
}

export const num = (text, decimal = 0) => {
  return Intl.NumberFormat('th-TH', {
    style: 'decimal',
    currency: 'THB',
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  }).format(text)
}


export const copyToClipboard = async text => {
  try {
    await navigator.clipboard.writeText(text)
    await Swal.fire('Copied?', t('text_copied'), 'success')
  } catch (error) {
    console.error(error.message)
  }
}

export const imageError = event => {
  event.target.src = '/img/no-image.png'
}


export const swal = async (title, message, type) => {
  if (typeof title === 'object') {
    return Swal.fire(title)
  } else {
    return Swal.fire(t(title), message, type)
  }
}
