<template>
  <header class="bg-[#000000B2] border-b-[4px] border-[#F72D95] sticky top-0 z-50">
    <div class="pr-[12px] pl-[12px]">
      <div class="flex justify-between items-center">
        <img
          src="/img/logo2.png"
          class="pt-[9px] pl-[3px]"
          width="130"
          :alt="appName"
        >
        <div
          v-if="store.isUserLoggedIn"
          class="flex space-x-[7px] pt-[10px] justify-end"
        >
          <div class="flex items-center justify-between pl-[5px] pr-[5px] w-[110px] h-[24px] rounded-[5px] bg-gradient-to-b from-[#FE59AF] to-[#F72D95]">
            <img
              src="/img/icon_dollar.svg"
              alt="Balance"
            >
            <span class="text-[#FFFFFF] text-[15px] font-[600] pt-[3px]">
              {{ balance }}
            </span>
          </div>
          <div
            class="cursor-pointer"
            @click="toggleMenu"
          >
            <img
              src="/img/icon_profile.svg"
              alt="Profile"
            >
          </div>
        </div>
      </div>
      <div
        v-if="store.isUserLoggedIn"
        class="grid grid-cols-2 pt-[3px]"
      >
        <div class="text-[#FFFFFF] font-[400] text-[12px] leading-[19.8px]">
          <div
            class="inline-block align-bottom cursor-pointer"
            @click="refreshTurnover"
          >
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-refresh"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" /><path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" /></svg>
          </div>
          <div
            :key="state.key"
            class="inline-block"
          >
            {{ $t('turnover') }}
            <span>{{ num(currentTurnover) }}</span> / <span>{{ num(turnover) }}</span>
            ({{ turnoverProgress }}%)
          </div>
        </div>
        <div class="space-x-[5px] text-right">
          <p class="text-[#FFFFFF] font-[300] text-[10px] leading-[16.5px]">
            <span>
              {{ $t('earning') }} <span class="font-[600] pl-[5px]">{{ sumEarning }}</span>
            </span>
            <RouterLink
              to="/user/affiliate"
              class="w-[30px] h-[24px] rounded-[5px] bg-gradient-to-b from-[#FE59AF] to-[#F72D95] font-[600] p-1 ms-1"
            >
              {{ $t('view') }}
            </RouterLink>
          </p>
        </div>
      </div>
      <div class="h-[10px] mb-[6px]">
        <FwbProgress
          :progress="turnoverProgress"
          color="default"
          size="sm"
          class="progress-bar"
        />
      </div>
    </div>
  </header>
</template>

<script setup>
import { useStore } from '@/stores'
import { FwbProgress } from 'flowbite-vue'

const emitter = inject('emitter')
const store = useStore()
const { locale } = useI18n({ useScope: 'global' })

const appName = import.meta.env.VITE_APP_NAME

const state = reactive({
  showMenu: false,
  key: (new Date()).getTime(),
})

const balance = computed(() => {
  return num(store.userData?.balance, 2)
})

const sumEarning = computed(() => {
  return num(store.userData?.earning)
})

const currentTurnover = computed(() => {
  return store.userData?.current_turnover
})

const turnover = computed(() => {
  return store.userData?.turnover
})

const turnoverProgress = computed(() => {
  return ! store.isUserLoggedIn ? 0 : parseFloat(currentTurnover.value) === 0 || parseFloat(turnover.value) === 0 ? 0 : Math.min(100, (currentTurnover.value / turnover.value) * 100).toFixed(0)
})

function toggleMenu() {
  store.setShowMenu(!store.showMenu)
}

function getUser() {
  store.getUserData(0)
}

function changeLanguage(lang) {
  locale.value = lang.i18nLang
  emitter.emit('change-language', lang.i18nLang)
  store.updateLanguage(lang.i18nLang)
}

function refreshTurnover() {
  getUser()
  setTimeout(() => {
    state.key = (new Date()).getTime()
  }, 1000)
}
</script>
